import { useDebounceFn } from '@vueuse/core'
import Copy from 'clipboard-copy'
import { nanoid } from 'nanoid'
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { useToastStore } from '@/stores/toast'
import byte from './fmt/byte'
import DateFormatter from './fmt/date'
import duration from './fmt/duration'
import NumberFormatter from './fmt/number'
import { phone } from './fmt/phone'
import StringCase from './fmt/string-case'

/**
 * Since unhandled rejection error is quite annoying when developer
 * doesn't need the return value, the promise will NOT be rejected
 * if the function is canceled by default. You need to specify
 * the option rejectOnCancel: true to capture the rejection.
 */
// const debouncedRequest = useDebounceFn(() => 'response', 1000, { rejectOnCancel: true })
export const debounce = useDebounceFn

export const fmt = {
  ...DateFormatter,
  ...NumberFormatter,
  ...StringCase,
  phone,
  byte,
  duration,
  url (str: string) {
    return location.protocol + '//' + import.meta.env.VITE_APP_URL + `/${str}`
  },
}

export const randomId = nanoid

/**
 * Watch the route full path, and trigger callback when query / params change
 * but don't trigger when route name change (move away to other page).
 * Optional watchSource to watch just specific part of the route (not fullPath).
 */
export const whenRouteChange = (callback: () => void, watchSource?: () => unknown) => {
  const route = useRoute()
  const routeName = route.name // cache route name
  watch(
    watchSource ?? (() => route.fullPath),
    () => {
      if (route.name === routeName) {
        callback()
      }
    },
    { immediate: true },
  )
}

/**
 * Filter array by javascript (example usage: search in region page list)
 */
export const jsSearch = <T>(needle: string, haystack: T[], callback: (item: T) => string) => {
  const sanitize = (str: string) => str.toLowerCase().replace(/\s+/g, '')
  const sanitizedNeedle = sanitize(needle)
  return haystack.filter((item: T) => {
    const sanitizedName = sanitize(callback(item))
    return sanitizedName.includes(sanitizedNeedle)
  })
}

// checkSaveToLeave(): boolean {
//   const textStore = useTextStore()
//   if (!this.saveToClose) {
//     const answer = window.confirm(textStore.prompCloseModalForm)
//     return answer
//   }
//   return true
// },

export const decodeHtml = (html: string) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export const copy = async (text: string) => {
  const toast = useToastStore()
  try {
    await Copy(text)
    toast.add('Teks telah disalin')
  } catch (error) {
    toast.add('Gagal menyalin teks')
    console.log(error)
  }
}

export const useEscListener = (callback: () => void) => {
  return (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      callback()
    }
  }
}

export const useDocumentOverflow = () => {
  const disableOverflow = () => {
    document.body.style.overflowY = 'hidden'
  }
  const reenableOverflow = () => {
    // delay because of vue lightbox
    setTimeout(() => {
      document.body.style.overflowY = ''
    }, 200)
  }
  return {
    disableOverflow,
    reenableOverflow,
  }
}
