export default [
  {
    path: '/',
    name: 'terms',
    component: () => import('@/layouts/term/layout-term.vue'),
    children: [
      {
        path: 'kebijakan-privasi',
        name: 'privacy policy',
        component: () => import('@/views/term/page-privacy-policy.vue'),
      },
      {
        path: 'syarat-ketentuan',
        name: 'term condition',
        component: () => import('@/views/term/page-term-condition.vue'),
      },
    ],
  },
]
