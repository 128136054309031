export default [
  {
    path: '/',
    component: () => import('@/layouts/app/layout-app.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/home/page-home.vue'),
      },
      {
        path: 'produk',
        name: 'product list',
        component: () => import('@/views/product/page-list.vue'),
      },
      {
        path: 'produk/:id',
        name: 'product detail',
        props: true,
        component: () => import('@/views/product/show/page-show.vue'),
      },
      {
        path: 'keranjang',
        name: 'shopping cart',
        component: () => import('@/views/shopping-cart/page-list.vue'),
      },
      {
        path: 'checkout',
        name: 'checkout',
        component: () => import('@/views/shopping-cart/page-checkout.vue'),
      },
      {
        path: 'pesanan',
        name: 'order list',
        component: () => import('@/views/order/page-list.vue'),
      },
      {
        path: 'pesanan/:id',
        name: 'order detail',
        props: true,
        component: () => import('@/views/order/show/page-show.vue'),
      },
      {
        path: 'profil',
        name: 'profile',
        component: () => import('@/views/profile/page-show.vue'),
      },
      {
        path: 'toko',
        name: 'sales official store',
        component: () => import('@/views/profile/official-store/page-list.vue'),
      },
      {
        path: 'toko/:id',
        name: 'sales official store detail',
        props: true,
        component: () => import('@/views/profile/official-store/show/page-show.vue'),
      },
      {
        path: 'pesanan-belum-lunas',
        name: 'order status credit',
        component: () => import('@/views/profile/order/page-list.vue'),
      },
      {
        path: 'notifikasi',
        name: 'notification list',
        component: () => import('@/views/notification/page-list.vue'),
      },
      {
        path: 'riwayat-poin',
        name: 'point logs',
        component: () => import('@/views/point-log/page-list.vue'),
      },
    ],
  },
]
