import { createRouter, createWebHistory } from 'vue-router'
import auth from './auth'
import shop from './shop'
import term from './term'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...auth,
    ...shop,
    ...term,
  ],
})

export default router
