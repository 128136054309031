<script setup lang="ts">
  import { computed } from 'vue'
  import { uiAvatarSrc } from '@/functions/user-photo'

  const props = defineProps<{
    src?: string | null,
    name: string,
    alt?: string,
  }>()

  const src = computed(() => {
    return props.src ?? uiAvatarSrc(props.name)
  })
</script>

<template>
  <g-img
    class="overflow-hidden"
    :src="src"
    :alt="alt" />
</template>
