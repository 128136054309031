<script setup lang="ts">
  import { computed, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'

  interface Props {
    modelValue: string | null,
    label?: string,
    choices: string[],
    placeholder?: string,
    query: string,
  }

  const props = defineProps<Props>()
  const route = useRoute()
  const router = useRouter()

  const emit = defineEmits<{
    (e: 'update:modelValue', data: string | null): void,
  }>()

  const selected = computed({
    get () {
      return props.modelValue
    },
    set (curr) {
      emit('update:modelValue', curr)
    },
  })

  watch(selected, (choice) => {
    router.replace({
      ...route, // name and params
      query: {
        // maintain other query params, but reset pagination
        ...route.query,
        page: undefined,
        [props.query]: choice ?? undefined,
      },
    })
  })

  emit('update:modelValue', null)

  if (route.query && route.query?.[props.query]) {
    emit('update:modelValue', String(route.query?.[props.query]))
  }
</script>

<template>
  <select
    v-model="selected"
    class="form-input w-32">
    <option
      v-if="placeholder"
      :value="null">
      {{ placeholder }}
    </option>
    <option
      v-for="(choice, index) in choices"
      :key="index"
      :value="choice">
      {{ choice }}
    </option>
  </select>
</template>

  <style lang="postcss" scoped>
    .item {
      @apply block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-primary-50 hover:text-primary-600;
    }
    .item-active {
      @apply text-primary font-medium border-r-4 border-primary;
    }
  </style>
