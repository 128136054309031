import 'virtual:uno.css'
import '@unocss/reset/tailwind.css'
import './styles/main.css'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import { createApp, markRaw } from 'vue'
import type { Router } from 'vue-router'
import globalComponents from '@/components'
import globalDirectives from '@/directives'
import App from './App.vue'
import router from './router'

const app = createApp(App)

const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

const head = createHead()

app.use(pinia)
app.use(head)
app.use(globalComponents)
app.use(globalDirectives)
app.use(router)

app.mount('#app')

declare module 'pinia' {
  interface PiniaCustomProperties {
    router: Router,
  }
}
