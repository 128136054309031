import { capitalCase, kebabCase } from 'change-case'

const stringUpperFirst = (str: string) => {
  return String(str).charAt(0).toUpperCase() + str.substring(1)
}

export default {
  kebabCase,
  titleCase: capitalCase,
  stringUpperFirst,
}
