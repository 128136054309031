<script setup lang="ts">
  import {
    addHours,
    addMinutes,
    addSeconds,
    startOfDay,
  } from 'date-fns'
  import { computed, onMounted, ref, watch } from 'vue'
  import { fmt } from '@/functions'

  const props = defineProps<{
    modelValue: Date,
    label?: string,
    endOfTime?: boolean,
    error?: string[],
    withoutMinutes?: boolean,
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: Date): void,
  }>()

  const pad = (n: number) => String(n).padStart(2, '0')

  const date = ref()
  const hour = ref('00')
  const minute = ref('00')
  const second = ref('00')

  const hours = Array.from({ length: 24 }, (v, i) => pad(i))
  const minutes = Array.from({ length: 12 }, (v, i) => {
    // only contains number multiples of 5 (0, 5, 10, 15, ..., 55)
    // if endOfTime === true, add 4: (4, 9, 14, ..., 59)
    let m: number = i * 5
    if (props.endOfTime) {
      m += 4
    }
    return pad(m)
  })

  const setForm = () => {
    const h = props.modelValue.getHours()
    const m = props.modelValue.getMinutes()
    const s = props.modelValue.getSeconds()
    hour.value = pad(h)
    minute.value = pad(m)
    second.value = pad(s)
    date.value = fmt.date(props.modelValue, 'yyyy-MM-dd')
  }

  onMounted(() => {
    setForm()
  })

  const computedDate = computed<Date>(() => {
    let result = startOfDay(new Date(date.value))
    result = addHours(result, Number(hour.value))
    result = addMinutes(result, Number(minute.value))
    result = addSeconds(result, Number(second.value))
    return result
  })

  watch(() => props.modelValue, () => setForm())

  watch(computedDate, newValue => {
    emit('update:modelValue', newValue)
  })
</script>

<template>
  <div>
    <label>{{ label }}</label>
    <div class="flex items-baseline gap-4">
      <input
        v-model="date"
        type="date"
        class="form-input">
      <div
        v-if="!withoutMinutes"
        class="form-input w-36 flex space-x-2">
        <select
          v-model="hour"
          class="cursor-pointer appearance-none px-1">
          <option
            v-for="h in hours"
            :key="h"
            :value="h">
            {{ h }}
          </option>
        </select>
        <div class="text-gray-400">
          :
        </div>
        <select
          v-model="minute"
          class="cursor-pointer appearance-none px-1">
          <option
            v-for="m in minutes"
            :key="m"
            :value="m">
            {{ m }}
          </option>
        </select>
        <div class="text-gray-400">
          :
        </div>
        <div class="px-1 text-gray-400">
          {{ second }}
        </div>
      </div>
    </div>
    <g-form-error :value="error" />
  </div>
</template>
