<script setup lang="ts">
  import { ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'

  const emit = defineEmits<{
    (e: 'searchUpdated', data: string): void,
  }>()

  const props = defineProps<{
    placeholder?: string,
    for?: string,
  }>()
  const route = useRoute()
  const router = useRouter()

  const text = ref('')
  const debounce = ref<number>()
  text.value = route.query.search as string

  watch(text, () => {
    clearTimeout(debounce.value)
    emit('searchUpdated', text.value)
    debounce.value = setTimeout(() => {
      submitSearch()
    }, 1000)
  })

  const submitSearch = () => {
    if (props.for === 'shop') {
      router.push({
        path: '/produk', // name and params
        query: {
          // maintain other query params, but reset pagination
          ...route.query,
          page: undefined,
          // append or remove search to query params
          cari: text.value ? text.value : undefined,
        },
      })
    } else {
      router.push({
        ...route, // name and params
        query: {
          // maintain other query params, but reset pagination
          ...route.query,
          page: undefined,
          // append or remove search to query params
          cari: text.value ? text.value : undefined,
        },
      })
    }
  }
</script>

<template>
  <form
    class="relative w-full"
    title="Cari Produk"
    @submit.prevent="submitSearch">
    <input
      v-model="text"
      class="form-input h-full w-full py-2 text-sm !pl-12"
      :placeholder="placeholder">
    <div class="absolute top-0 h-full flex items-center px-4">
      <fa-icon
        icon="material-symbols:search"
        class="text-gray-500" />
    </div>
  </form>
</template>
