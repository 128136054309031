<script setup lang="ts">
  import { computed } from 'vue'
  import { fmt } from '@/functions'

  const props = defineProps<{ value?: string | string[] }>()

  const message = computed(() => {
    if (Array.isArray(props.value) && props.value.length) {
      return fmt.stringUpperFirst(props.value[0])
    }

    return null
  })
</script>

<template>
  <span class="text-left text-xs text-red-500">{{ message }}</span>
</template>

<style lang="postcss" scoped>

</style>
