<script setup lang="ts">
  import { useHead } from '@vueuse/head'
  import { pwaInfo } from 'virtual:pwa-info'
  import { registerSW } from 'virtual:pwa-register'

  if (pwaInfo) {
    const href = pwaInfo.webManifest.href
    /* add link to head: href is the link */
    const linkElement = document.createElement('link')
    linkElement.setAttribute('rel', 'manifest')
    linkElement.setAttribute('href', href)
    if (pwaInfo.webManifest.useCredentials) {
      linkElement.setAttribute('crossorigin', 'use-credentials')
    }
    document.head.appendChild(linkElement)
  }

  registerSW({ immediate: true })

  useHead({
    title: 'SALESA - Solusi Bisnis Effisien Tanpa Kendala',
    meta: [
      {
        name: 'description',
        content: 'Salesa Aplikasi untuk penjualan membantu Sales',
      },
    ],
  })
</script>

<template>
  <router-view />
  <g-toast />
</template>
